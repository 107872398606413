<template>
  <div class="content">
    <sub-title>슬롯</sub-title>
    <div class="casinoslot_panel">
      <money-chip-change-comp></money-chip-change-comp>
      <div class="items">
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'EVOPLAY'}}">
          <div class="pic" @click="getGameListByCode('EVOPLAY')">
            <img src="../../../assets/images/casino/s1.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'PragmaticPlay'}}">
          <div class="pic" @click="getGameListByCode('PragmaticPlay')" alt="">
            <img src="../../../assets/images/casino/s2.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Habanero'}}">
          <div class="pic"  @click="getGameListByCode('Habanero')">
            <img src="../../../assets/images/casino/s3.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'CQ9'}}">
          <div class="pic"  @click="getGameListByCode('CQ9')" alt="">
            <img src="../../../assets/images/casino/s4.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'PlayStar'}}">
          <div class="pic"  @click="getGameListByCode('PlayStar')">
            <img src="../../../assets/images/casino/s5.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'GameArt'}}">
          <div class="pic" @click="getGameListByCode('GameArt')">
            <img src="../../../assets/images/casino/s6.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Relax Gaming'}}">
          <div class="pic" @click="getGameListByCode('Relax Gaming')">
            <img src="../../../assets/images/casino/s7.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Booongo'}}">
          <div class="pic"  @click="getGameListByCode('Booongo')">
            <img src="../../../assets/images/casino/s8.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'PlaySon'}}">
          <div class="pic" @click="getGameListByCode('PlaySon')">
            <img src="../../../assets/images/casino/s9.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Nolimit City'}}">
          <div class="pic" @click="getGameListByCode('Nolimit City')">
            <img src="../../../assets/images/casino/s10.jpg" alt="">
          </div>
        </router-link>
      </div>
    </div>
  </div>

</template>


<script>

    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
    import {casinoMixin, checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import MoneyChipChangeComp from "@/views/afront/casino/MoneyChipChangeComp.vue";
    import Tgame365Ruollet from "@/views/leisuregame/children/Tgame365Ruollet.vue";
    export default {
      name: "SlotHonor",
      mixins: [postionMixin,casinoMixin,checkLoginOnCreateMinxin],
      components: {
        Tgame365Ruollet,
        MoneyChipChangeComp,
        SubTitle

      },
      data() {
        return {
          position: "슬롯",
        }
      },
      methods: {

      },
      created() {
      },
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcasinoslot.css");
</style>